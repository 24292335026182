<template>
  <div class="div">
    <van-field
      v-model="message"
      rows="2"
      autosize
      label="输入卡密"
      type="textarea"
      placeholder="在此输入购买的卡密"
      show-word-limit
    />
    <van-button plain hairline type="info" color="#464646" @click="btn_click"
      >充值卡密</van-button
    >
  </div>
</template>

<script>
export default {
  name: "add_project",
  data() {
    return {
      message: "",
      state: false,
    };
  },
  methods: {
    async btn_click() {
      if (this.message.length === 0) {
        this.$dialog.alert({ message: "卡密都不输入,你小子是在玩我？" });
        return;
      }
      if (this.state) {
        return;
      }
      this.state = true;
      let key = this.message;
      key = key.replace(/\r\n/g, "");
      key = key.replace(/\n/g, "");
      let ret = await this.$api.fn_key_push(key);
      let c = ret.data;
      if (c.message === "token失效,请重新登录") {
        localStorage.clear();
        this.$toast("退出登录");
        this.$router.replace({ path: "/login" });
        return;
      }
      ret = ret.data;
      if (ret.message === "ok") {
        this.$toast("充值成功");
      } else {
        this.$toast(ret.message + "\r\n有疑问请联系客服帮忙查询");
      }
      this.state = true;
    },
  },
};
</script>

<style scoped>
.div {
  text-align: center;
  margin-top: 48%;
  margin-left: 1rem;
  margin-right: 1rem;
  border: 1px solid #464646;
}
</style>